import { useState } from "react";
import { FaArrowLeft, FaArrowRight, FaArrowRightLong } from "react-icons/fa6";
import BeninRepublicEvent from "../../assets/img/event1.webp";
import IGGG from "../../assets/img/igg1.webp";
import IGGGG from "../../assets/img/igg2.webp";
import Togo from "../../assets/img/togo1.webp";
import IGG from "../../assets/img/igg.webp";
import { Link, useNavigate } from "react-router-dom";
const Itinerary = () => {
  const [togo, setTogo] = useState(false);
  const [iggg, setIggg] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [IGGG, IGGGG];
  const router = useNavigate();
  const openFullScreen = () => {
    setTogo(true);
  };
  const openFullIggg = () => {
    setIggg(true);
  };

  const closeFullScreen = () => {
    setTogo(false);
  };
  const closeFullIggg = () => {
    setIggg(false);
  };

  const navigatePastEvents = (event: any) => {
    router(`/events/${event}`);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };
  return (
    <div>
      <div className="bg-black text-white font-RalewayBold xxs:py-[70px] py-[50px] px-[3%]">
        <div className="flex justify-between">
          <div className="minimd:w-auto md:w-[70%]">
            <h1 className="md:text-[25px] sm:text-[23px] text-[21px] pb-3">
              UP NEXT
            </h1>
            <div className="flex gap-7 items-center">
              <div className="bg-white sm:h-[140px] h-[120px] inline-flex rounded-[8px] text-center items-center justify-center p-5 text-black">
                <div>
                  <h1 className="sm:text-[16px] text-[14px] font-RalewayRegular">
                    FEB
                  </h1>
                  <h1 className="md:text-[35px] sm:text-[33px] text-[31px] leading-8">
                    25
                    <span className="font-RalewayRegular sm:text-[12px] text-[10px]">
                      TH
                    </span>
                  </h1>
                  <h1 className="font-RalewayRegular sm:text-[12px] text-[10px]">
                    TO MAR 1ST
                  </h1>
                </div>
              </div>
              <div>
                <h1 className="font-RalewayRegular md:text-[30px] sm:text-[28px] text-[26px]">
                  Togo - Gospel Crusade
                </h1>
                <h1 className="font-RalewayRegular pb-2 md:text-[18px] sm:text-[16px] text-[14px]">
                  We are happy to be travelling to Togo for this great crusade.
                </h1>
                <button
                  onClick={openFullScreen}
                  className="bg-white mt-4 rounded-[8px] md:text-[16px] sm:text-[14px] text-[12px] text-black flex items-center gap-2 py-3 px-4"
                >
                  View Event
                  <FaArrowRightLong size={15} />
                </button>
              </div>
            </div>
          </div>
          <div className="w-[30%]  md:block hidden">
            <img src={Togo} className="w-full h-auto" alt="ese-beloved" />
          </div>
        </div>
        <div className="flex mt-4 justify-between">
          <div className="minimd:w-auto md:w-[70%]">
            <div className="flex gap-7 items-center">
              <div className="bg-white sm:h-[140px] h-[120px] inline-flex rounded-[8px] text-center items-center justify-center p-5 text-black">
                <div>
                  <h1 className="sm:text-[16px] text-[14px] font-RalewayRegular">
                    JAN
                  </h1>
                  <h1 className="md:text-[35px] sm:text-[33px] text-[31px] leading-8">
                    25
                    <span className="font-RalewayRegular sm:text-[12px] text-[10px]">
                      TH
                    </span>
                  </h1>
                </div>
              </div>
              <div>
                <h1 className="font-RalewayRegular md:text-[30px] sm:text-[28px] text-[26px]">
                  City Gates Conference Centre, Ilford, Clements Road, Ilford,
                  UK
                </h1>

                <button
                  onClick={openFullIggg}
                  className="bg-white my-1 rounded-[8px] md:text-[16px] sm:text-[14px] text-[12px] text-black flex items-center gap-2 py-3 px-4"
                >
                  View Event
                  <FaArrowRightLong size={15} />
                </button>
                <Link
                  to="https://IGOGW25.eventbrite.co.uk"
                  className="text-white hover:underline inline-block text-[13px]"
                  target="_blank"
                >
                  <h1>Click here to register for this event</h1>
                </Link>
              </div>
            </div>
          </div>
          <div className="w-[30%]  md:block hidden">
            <img src={IGG} className="w-full h-auto" alt="ese-beloved" />
          </div>
        </div>
        <hr
          className="text-white"
          style={{ marginTop: "40px", marginBottom: "40px" }}
        />
        <div>
          <h1 className="uppercase text-[25px] pb-3">Past Events</h1>
        </div>
        <div>
          <div className="flex gap-7">
            <div className="bg-white inline-flex rounded-[8px] text-center items-center justify-center p-5 text-black">
              <div>
                <h1 className="sm:text-[16px] text-[14px] font-RalewayRegular">
                  2024
                </h1>
              </div>
            </div>
            <div>
              <h1 className="font-RalewayRegular md:text-[30px] sm:text-[28px] text-[26px]">
                Benin Republic - Gospel Crusade 2024
              </h1>
              <button
                onClick={() =>
                  navigatePastEvents("benin-republic-crusade-2024")
                }
                className="bg-white mt-4 rounded-[8px] md:text-[16px] sm:text-[14px] text-[12px] text-black flex items-center gap-2 py-3 px-4"
              >
                View Gallery
                <FaArrowRightLong size={15} />
              </button>
            </div>
          </div>
          <div className="flex gap-7 pt-7">
            <div className="bg-white inline-flex rounded-[8px] text-center items-center justify-center p-5 text-black">
              <div>
                <h1 className="sm:text-[16px] text-[14px] font-RalewayRegular">
                  2024
                </h1>
              </div>
            </div>
            <div>
              <h1 className="font-RalewayRegular md:text-[30px] sm:text-[28px] text-[26px]">
                Ghana Crusade 2024
              </h1>
              <button
                onClick={() => navigatePastEvents("ghana-crusade-2024")}
                className="bg-white mt-4 rounded-[8px] md:text-[16px] sm:text-[14px] text-[12px] text-black flex items-center gap-2 py-3 px-4"
              >
                View Gallery
                <FaArrowRightLong size={15} />
              </button>
            </div>
          </div>
          <div className="flex gap-7 pt-7">
            <div className="bg-white inline-flex rounded-[8px] text-center items-center justify-center p-5 text-black">
              <div>
                <h1 className="sm:text-[16px] text-[14px] font-RalewayRegular">
                  2023
                </h1>
              </div>
            </div>
            <div>
              <h1 className="font-RalewayRegular md:text-[30px] sm:text-[28px] text-[26px]">
                Liberia Crusade 2023
              </h1>
              <button
                onClick={() => navigatePastEvents("liberia-crusade-2023")}
                className="bg-white mt-4 rounded-[8px] md:text-[16px] sm:text-[14px] text-[12px] text-black flex items-center gap-2 py-3 px-4"
              >
                View Gallery
                <FaArrowRightLong size={15} />
              </button>
            </div>
          </div>
          <div className="flex gap-7 pt-7">
            <div className="bg-white inline-flex rounded-[8px] text-center items-center justify-center p-5 text-black">
              <div>
                <h1 className="sm:text-[16px] text-[14px] font-RalewayRegular">
                  2023
                </h1>
              </div>
            </div>
            <div>
              <h1 className="font-RalewayRegular md:text-[30px] sm:text-[28px] text-[26px]">
                Missions to Nigeria 2023
              </h1>
              <button
                onClick={() => navigatePastEvents("missions-to-nigeria-2023")}
                className="bg-white mt-4 rounded-[8px] md:text-[16px] sm:text-[14px] text-[12px] text-black flex items-center gap-2 py-3 px-4"
              >
                View Gallery
                <FaArrowRightLong size={15} />
              </button>
            </div>
          </div>
          <div className="flex gap-7 pt-7">
            <div className="bg-white inline-flex rounded-[8px] text-center items-center justify-center p-5 text-black">
              <div>
                <h1 className="sm:text-[16px] text-[14px] font-RalewayRegular">
                  2023
                </h1>
              </div>
            </div>
            <div>
              <h1 className="font-RalewayRegular md:text-[30px] sm:text-[28px] text-[26px]">
                Missions trip to Kenya 2023
              </h1>
              <button
                onClick={() => navigatePastEvents("missions-to-kenya-2023")}
                className="bg-white mt-4 rounded-[8px] md:text-[16px] sm:text-[14px] text-[12px] text-black flex items-center gap-2 py-3 px-4"
              >
                View Gallery
                <FaArrowRightLong size={15} />
              </button>
            </div>
          </div>
          <div className="flex gap-7 pt-7">
            <div className="bg-white inline-flex rounded-[8px] text-center items-center justify-center p-5 text-black">
              <div>
                <h1 className="sm:text-[16px] text-[14px] font-RalewayRegular">
                  2020
                </h1>
              </div>
            </div>
            <div>
              <h1 className="font-RalewayRegular md:text-[30px] sm:text-[28px] text-[26px]">
                Missions trip to Nigeria 2020
              </h1>
              <button
                onClick={() => navigatePastEvents("missions-to-nigeria-2020")}
                className="bg-white mt-4 rounded-[8px] md:text-[16px] sm:text-[14px] text-[12px] text-black flex items-center gap-2 py-3 px-4"
              >
                View Gallery
                <FaArrowRightLong size={15} />
              </button>
            </div>
          </div>
          <div className="flex gap-7 pt-7">
            <div className="bg-white inline-flex rounded-[8px] text-center items-center justify-center p-5 text-black">
              <div>
                <h1 className="sm:text-[16px] text-[14px] font-RalewayRegular">
                  2016
                </h1>
              </div>
            </div>
            <div>
              <h1 className="font-RalewayRegular md:text-[30px] sm:text-[28px] text-[26px]">
                Missions to India 2016
              </h1>
              <button
                onClick={() => navigatePastEvents("missions-to-india-2016")}
                className="bg-white mt-4 rounded-[8px] md:text-[16px] sm:text-[14px] text-[12px] text-black flex items-center gap-2 py-3 px-4"
              >
                View Gallery
                <FaArrowRightLong size={15} />
              </button>
            </div>
          </div>
        </div>
        <hr
          className="text-white"
          style={{ marginTop: "40px", marginBottom: "40px" }}
        />
      </div>
      {togo && (
        <div className="fixed top-0 left-0 z-50 w-full h-full bg-black flex justify-center items-center">
          <div className="max-w-screen-lg w-full h-full flex justify-center items-center">
            <img
              src={Togo}
              alt="event"
              className="object-contain h-full w-full"
              onClick={closeFullScreen}
            />
          </div>
        </div>
      )}
      {iggg && (
        <div className="fixed top-0 left-0 z-50 w-full h-full bg-black flex justify-center items-center">
          <div className="max-w-screen-lg w-full h-full flex justify-center items-center relative">
            <button
              onClick={handlePrevImage}
              className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full"
            >
              <FaArrowLeft size={24} />
            </button>
            <img
              src={images[currentImageIndex]}
              alt="event"
              className="object-contain h-full w-full"
              onClick={closeFullIggg}
            />
            <button
              onClick={handleNextImage}
              className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full"
            >
              <FaArrowRight size={24} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Itinerary;
